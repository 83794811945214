const startYear = "2024";

export default function Copyright() {
  const dateObj = new Date();
  const year = dateObj.getFullYear().toString();
  if (year === startYear) {
    return <p>© {startYear} sk337. All Rights Reserved.</p>;
  } else {
    return (
      <p>
        © {startYear} - {year} sk337. All Rights Reserved.
      </p>
    );
  }
}
